import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid, Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const list = [
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/social_justice_new.png",
      title: "Social Justice",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/education_new.png",
      title: "Education",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/arts_culture_new.png",
      title: "Art & Culture",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/advocacy_new.png",
      title: "Advocacy",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/humanitarian_aid_new.png",
      title: "Humanitarian Aid",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/youth_development_new.png",
      title: "Youth Development",
    },
    {
      image:
        "https://www.dvnetwork.org/images/category-icons-v2/innovation_new.png",
      title: "Innovation",
    },
    {
      image: "https://www.dvnetwork.org/images/category-icons-v2/other_new.png",
      title: "Other",
    },
  ];

  return (
    <div className={classes.root}>
      <Typography>Donate by Category</Typography>
      <Grid container>
        {list.map(({ image, title }) => (
          <Grid item>
            <Card>
              <CardContent>
                <img src={image} alt={title} />
                <Typography>{title}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
