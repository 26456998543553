import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { Grid } from "@material-ui/core";

import TermsOfUseMD from "../docs/TermsOfUse.md";

function TermsOfUse() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(TermsOfUseMD)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <Grid container>
      <ReactMarkdown children={markdown} />
    </Grid>
  );
}

export default TermsOfUse;
