import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";

function GiveTheGift() {
  return (
    <Grid container>
      <Grid item>
        <Typography>Give the Gift of Giving</Typography>
        <Typography>
          Share the gift of giving with someone you care about with a Dasvandh
          Network gift card. Donate to a project in their honor or empower them
          to donate to a project they are passionate about.
        </Typography>
        <Button>Choose a Gift</Button>
      </Grid>
    </Grid>
  );
}

export default GiveTheGift;
