import {
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SupportCause() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [sortBy, setSortBy] = React.useState('close-to-goal');
  const [type, setType] = React.useState('all-causes');
  const [category, setCategory] = React.useState('all-category');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item>
        <Typography>Support a Cause</Typography>
        <Typography>
          We strive to present the projects and organizations that speak to each
          potential donor – thus allowing you to maintain a “Portfolio of
          Giving” in an informed and convenient way. By maintaining a portfolio
          of projects on DVN you contribute to, you can measure the impact your
          donations are having by keeping up-to-date on the progress of your
          projects.
        </Typography>
      </Grid>

      <Grid item>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Projects" {...a11yProps(1)} />
            <Tab label="Fundraisers" {...a11yProps(2)} />
            <Tab label="Organizations" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <FormControl className={classes.formControl}>
          <InputLabel id="sort-by-label">Sort by</InputLabel>
          <Select
            labelId="sort-by-label"
            id="sort-by"
            value={sortBy}
            onChange={(event) => {
              setSortBy(event.target.value);
            }}
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"close-to-goal"}>Close to Goal</MenuItem>
            <MenuItem value={"most-recent"}>Most Recent</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="type-filter-for-causes-lable">Type</InputLabel>
          <Select
            labelId="type-filter-for-causes-lable"
            id="type-filter-for-causes"
            value={type}
            onChange={(event) => {
              setType(event.target.value);
            }}
          >
            <MenuItem value={"all-causes"}>All Causes</MenuItem>
            <MenuItem value={"tax-deductible"}>Tax Deductible</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="category-label-for-causes">Category</InputLabel>
          <Select
            labelId="category-label-for-causes"
            id="category-label"
            value={category}
            onChange={(event) => {
              setCategory(event.target.value);
            }}
          >
            <MenuItem value={"all-category"}>All Category</MenuItem>
            <MenuItem value={"advocacy"}>Advocacy</MenuItem>
            <MenuItem value={"arts-and-culture"}>Arts & Culture</MenuItem>
            <MenuItem value={"education"}>Education</MenuItem>
            <MenuItem value={"humanitarian-aid"}>Humanitarian Aid</MenuItem>
            <MenuItem value={"innovation"}>Innovation</MenuItem>
            <MenuItem value={"social-justice"}>Social Justice</MenuItem>
            <MenuItem value={"youth-development"}>Youth Development</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </FormControl>

        <TabPanel value={value} index={0}>
          Item One
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default SupportCause;
