import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid, Card, CardContent, CardHeader, Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const list = [
    {
      logo: "https://www.dvnetwork.org/images/how-it-works-icons/1.png",
      title: "Our commitment to trust and transparency",
      description:
        "Our application process evaluates projects and organizations on a number of criteria. By establishing transparent governance procedures, we continue to provide donors with a platform they can trust and projects a service they can use to succeed.",
    },
    {
      logo: "https://www.dvnetwork.org/images/how-it-works-icons/2.png",
      title: "We are about compassion, not fees",
      description:
        "We believe campaign organizers should be able to keep the funds they raise. Unlike other fundraising platforms, we do not charge a fee for using the Dasvandh Network platform.",
    },
    {
      logo: "https://www.dvnetwork.org/images/how-it-works-icons/3.png",
      title: "A platform to tell your story far and wide",
      description:
        "Fundraising is a snap at Dasvandh Network. Once you launch your campaign, you can easily share it with friends and family. Our social integrations and administrative support allow you to better engage donors.",
    },
    {
      logo: "https://www.dvnetwork.org/images/how-it-works-icons/4.png",
      title: "The spirit of DVN, the power of the Panth",
      description:
        "Our unique crowdfunding platform is tailored specifically for initiatives and organizations that benefit the Sikh community, appealing to people interested in supporting Sikh causes and rekindling the spirit of Dasvandh.",
    },
  ];

  return (
    <div className={classes.root}>
      <Typography>How it Works</Typography>
      <Typography>
        Dasvandh Network is a non-profit organization dedicated to inspire
        giving within the Sikh Community. Here's why Sikh activists collaborate
        with Dasvandh Network to get their projects funded.
      </Typography>
      <Grid container>
        {list.map(({ log, title, description }) => (
          <Grid item>
            <Card>
              <CardHeader
                avatar={
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                }
                title={title}
                subheader={description}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
