import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { Grid } from "@material-ui/core";

import PrivacyPolicyMD from "../docs/PrivacyPolicy.md";

function PrivacyPolicy() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(PrivacyPolicyMD)
      .then((res) => res.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, []);

  return (
    <Grid container>
      <ReactMarkdown children={markdown} />
    </Grid>
  );
}

export default PrivacyPolicy;
