import React from "react";

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
} from "@material-ui/core";

function WhoLovesYou() {
  const list = [
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
    {
      name: "Mr. XYZ",
      avatar:
        "https://www.dvnetwork.org/images/project-creators/apar_singh-bhagat_puran.jpg",
      cover:
        "https://www.dvnetwork.org/images/project-creators/background/bhagatpuran.jpg",
      description:
        "The Dasvandh Network is a great platform and tool to reach out to the sangat on a large scale and explain the goals, objectives, and motivation behind the projects or organizations. Tera Hands was brand new idea/project and we weren't sure how we will fundraise to have enough funds for the rest of the year for our goals. As DVN has a framework already set up for fundraising, it makes super easy for new projects or organizations like Tera hands to fundraise. With the help DVN and then sangat, Tera Hands was able to reach the fundraising goal. I recommend all new budding or seasoned activist, to employ DVN as their fundraising platform. ",
      orgName: "NGO Organisation Name",
    },
  ];

  return (
    <Grid container>
      <Grid item>
        <Typography>Project Creators Who Love Us</Typography>

        <Grid container>
          {list.map(({ name, avatar, cover, description, orgName }) => (
            <Grid item>
              <Card>
                <CardMedia
                  image={cover}
                  styles={{
                    height: "300px",
                  }}
                />
                <CardHeader title={name} subheader={description} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WhoLovesYou;
