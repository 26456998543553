import React from "react";

import { Grid, Input, Button, Typography, FormGroup } from "@material-ui/core";

function ContactUs() {
  return (
    <Grid container>
      <Typography>Contact Us</Typography>

      <Grid item>
        <Grid container>
          <Grid item>
            <Typography>
              Before you continue, have you looked in the FAQ yet? It’s the
              quickest way to find your answer to most questions. Click here.
            </Typography>
            <Typography>
              For any questions, please fill out the form below and we will be
              in touch shortly.
            </Typography>

            <form>
              <Input onChange={() => {}} placeholder="Name" value={""} />
              <Input onChange={() => {}} placeholder="Email" value={""} />
              <Input
                onChange={() => {}}
                placeholder="Mobile Number"
                value={""}
              />
              <Input
                onChange={() => {}}
                placeholder="Question / Comment"
                value={""}
              />

              <Button>Send</Button>
            </form>
          </Grid>
          <Grid item>
            <Typography>Contact Infomation</Typography>

            <Typography>
              Dasvandh Network 6002 Camp Bullis Rd San Antonio, TX 78257
            </Typography>

            <Typography>+248-906-8039</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
