import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./pages/About";
import Birthday from "./pages/Birthday";
import Blog from "./pages/Blog";
import Blogs from "./pages/Blogs";
import ContactUs from "./pages/ContactUs";
import CorporateMatching from "./pages/CorporateMatching";
import DharmicStudentAssociation from "./pages/DharmicStudentAssociation";
import ErrorPage from "./pages/ErrorPage";
import FAQs from "./pages/FAQs";
import FiscalSponsorship from "./pages/FiscalSponsorship";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProjectBudgetTemplate from "./pages/ProjectBudgetTemplate";
// import Projects from "./pages/Projects";
import Register from "./pages/Register";
import SignIn from "./pages/SignIn";
import StartProject from "./pages/StartProject";
import SupportCause from "./pages/SupportCause";
import TermsOfUse from "./pages/TermsOfUse";
import VsiBox from "./pages/VsiBox";

function AppRoutes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" exact component={About} />
        <Route path="/birthday" exact component={Birthday} />
        <Route path="/blogs" exact component={Blogs} />
        <Route path="/blogs/:blogId" exact component={Blog} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/corporate-matching" exact component={CorporateMatching} />
        <Route
          path="/dharmic-student-association"
          exact
          component={DharmicStudentAssociation}
        />
        <Route path="/faqs" exact component={FAQs} />
        <Route path="/fiscal-sponsorship" exact component={FiscalSponsorship} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route
          path="/project-budget-template"
          exact
          component={ProjectBudgetTemplate}
        />
        <Route path="/start-a-project" exact component={StartProject} />
        <Route path="/support-a-cause" exact component={SupportCause} />
        <Route path="/terms-of-use" exact component={TermsOfUse} />
        <Route path="/vsi-box" exact component={VsiBox} />

        <Route path="/register" exact component={Register} />
        <Route path="/signin" exact component={SignIn} />

        <Route component={ErrorPage} />
      </Switch>
    </Router>
  );
}

export default AppRoutes;
