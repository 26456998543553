import React from "react";

import { Grid, Typography } from "@material-ui/core";

function Home() {
  return (
    <Grid container>
      <Typography>About Us</Typography>

      <Grid item>
        <Typography>
          The Dasvandh Network is an online giving platform where donors and
          organizations can actively participate in the spirit of Dasvandh, or
          share part of one’s earnings towards the betterment of society. We
          assist organizations and grass-roots community projects in finding new
          donors through web-based fundraising and communication pages, social
          media tools, and donation processing. In addition, we utilize many
          offline methods to promote these projects, including conferences,
          Gurdwara visits, etc. The Dasvandh Network is a non-religious entity
          (as evident in our by-laws). The organization is focused on furthering
          humanity as a whole—a concept that is fundamental to Sikhism.
        </Typography>

        <Typography>
          We provide an opportunity for donors to discover inspiring project
          ideas and give to various organizations. DVN makes every effort to
          ensure that your funds are used efficiently and responsibly.
        </Typography>

        <Typography>
          The goal of the Dasvandh Network is to bring Sikh and community giving
          to the next level. The progress of our community remains stunted due
          to the lack of consistent funds available to both established
          organizations and community projects. We must reignite the spirit of
          Dasvandh and promote humanitarian ideals by supporting innovative
          projects & organizations.
        </Typography>
      </Grid>

      <Grid item>
        <Grid container>
          <Grid item>
            <Typography>MISSION</Typography>
            <Typography>
              To inspire an increase in the level of giving to organizations and
              charitable projects across North America by providing
              transparency, trust, access, and awareness of Panthic-related
              efforts.
            </Typography>
          </Grid>

          <Grid item>
            <Typography>VISION</Typography>
            <Typography>
              We have seen how small contributions toward the appropriate
              organization can lead to significant change; however, such
              contributions are still a rare occurrence. Through proper funding
              and management, we hope to realize the many visions the community
              has– to dedicate an abundance of resources toward advocacy,
              education, and public relations initiatives.
            </Typography>
          </Grid>

          <Grid item>
            <Typography>WHY?</Typography>
            <Typography>
              Today, at least $0.20 of every dollar raised (20%) by non-profit
              organizations goes towards fundraising expenses, reaching as high
              as 50% for some events, such as banquets and galas. We aim to
              streamline fundraising for organizations and project groups to
              make this effort much more cost-efficient for the community
              overall. We also strive to make fundraising more accessible for
              projects, so organizers can focus on delivering valuable services
              and programs
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography>Our Process</Typography>
        <Typography>
          20 cents of every dollar (20%) raised by non-profit organizations goes
          towards fundraising expenses (U.S. national average). DVN streamlines
          fundraising to make this effort much more cost-efficient for the
          community overall. Only 3.5% of funds raised by credit card will go
          towards processing fees ($0.50 for ACH transactions and $0 for check
          payments). These funds are NOT retained by DVN – they are used to
          cover 3rd party payment processing fees and not cover DVN
          administrative costs.
        </Typography>
        <Typography>
          Dasvandh Network is able to pass through 96.5% - 100% (depending on
          method of payment) of funds raised because of donations made to DVN by
          strategic supporters and through optional tips in the donation
          process.
        </Typography>
        <img
          src="https://dvncorestorageprod.blob.core.windows.net/files/Page/29/Image/blobid0_20180830112815267.png"
          alt="hi"
        />
      </Grid>

      <Grid item>
        We also strive to make fundraising more accessible for Sikh projects, so
        organizers can focus on delivering valuable services and programs.
      </Grid>
    </Grid>
  );
}

export default Home;
