import React from "react";
import { Grid, Typography, Card, CardHeader } from "@material-ui/core";

function MakeThingsPossible() {
  const data = {
    totalAmountRaised: "$3,000,000",
    currency: "USD",
    users: 1230900,
    campaigns: 123123,
    organisations: 123,
  };

  return (
    <Grid container>
      <Typography>We are changing the way of making things possible</Typography>
      <Typography>
        Raise money for panthic projects with powerful campaigns, personal
        fundraisers, and corporate matching opportunities.
      </Typography>

      <Grid item>
        <Card>
          <CardHeader
            title={data.totalAmountRaised}
            subheader="Total raised across all projects and organizations."
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader
            title={data.users}
            subheader="Supporting panthic initiatives across North America."
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader
            title={data.campaigns}
            subheader="Raising funds to fuel activism in the Sikh community."
          />
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader
            title={data.organisations}
            subheader="Working to reignite the spirit of Dasvandh."
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default MakeThingsPossible;
