import React from "react";

import Carousal from "../components/Carousal.jsx";
import DonateByCategory from "../components/DonateByCategory.jsx";
import HowItWorks from "../components/HowItWorks.jsx";
import GiveTheGiftOfGiving from "../components/GiveTheGiftOfGiving.jsx";
import MakeThingsPossible from "../components/MakeThingsPossible.jsx";
import ProjectCreatorsWhoLoveUs from "../components/ProjectCreatorsWhoLoveUs.jsx";

function App() {
  return (
    <div>
      <Carousal />
      <DonateByCategory />
      <HowItWorks />
      <GiveTheGiftOfGiving />
      <ProjectCreatorsWhoLoveUs />
      <MakeThingsPossible />
    </div>
  );
}

export default App;
