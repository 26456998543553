import React from "react";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <>
      <AppHeader />
      <AppRoutes />
      <AppFooter />
    </>
  );
}

export default App;
