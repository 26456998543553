import React from "react";

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Button,
} from "@material-ui/core";

function Home() {
  return (
    <Grid container>
      <Grid item>
        <Typography>Present Yourself on DVN</Typography>
        <Typography>
          DVN enables Sikh activists to present their projects and organizations
          on our online giving platform, allowing donors to make an informed and
          convenient choice.
        </Typography>

        <Grid container>
          <Grid item>
            <Card>
              <CardHeader title="Start A Project" />
              <CardMedia image="https://www.dvnetwork.org/images/placeholder/how-it-works1.PNG" />
              <CardContent>
                <Typography>
                  We invite individuals and start-up project teams to partner
                  with DVN to raise awareness and request funding for their
                  initiatives.
                </Typography>

                <Button>Start a Project</Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader title="Join As An Organization" />
              <CardMedia image="https://www.dvnetwork.org/images/placeholder/how-it-works2.PNG" />
              <CardContent>
                <Typography>
                  DVN is here to help established non-profit or 501(c)(3)
                  organizations build another reliable stream of funding so you
                  can focus on bringing your projects to successful completion.
                </Typography>

                <Button>List your Organization</Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader title="Create a Fundraising Page" />
              <CardMedia image="https://www.dvnetwork.org/images/placeholder/how-it-works3.PNG" />
              <CardContent>
                <Typography>
                  As an individual, you can set up a fundraising page through
                  DVN to raise awareness and raise funds for any project or
                  organization on our platform.
                </Typography>

                <Button>Create Fundraiser</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Card>
          <Typography>
            Existing DVN Organization? List your project here.
          </Typography>
          <Button>Add a Project</Button>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Home;
