import React from "react";

import { Grid, Typography, Button, IconButton } from "@material-ui/core";

function AppFooter() {
  return (
    <Grid
      container
      style={{
        backgroundColor: "black",
        color: "white",
      }}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <Typography>Fundraise</Typography>

            <Typography>Start A Project</Typography>
            <Typography>Project Budget Template</Typography>
            <Typography>Fiscal Sponsorship</Typography>
            <Typography>Sikh Student Association</Typography>
            <Typography>Celebrate Your Birthday</Typography>
            <Typography>Dasvandh Box Initiative for Children</Typography>
          </Grid>

          <Grid item>
            <Typography>Donate</Typography>

            <Typography>Support A Cause</Typography>
            <Typography>Give a Gift Card</Typography>
            <Typography>Corporate Matching</Typography>
          </Grid>

          <Grid item>
            <Typography>About Us</Typography>

            <Typography>About VSI</Typography>
            <Typography>Contact Us</Typography>
            <Typography>Terms of Use</Typography>
            <Typography>Privacy Policy</Typography>
            <Typography>Blogs</Typography>
          </Grid>

          <Grid item>
            <Typography>Connect with Us</Typography>

            <Typography>Sign up for the Dasvandh Network Newsletter</Typography>
            <Button>Subscribe</Button>
            <Typography>Follow us</Typography>

            <div>
              <IconButton>FB</IconButton>
              <IconButton>IG</IconButton>
              <IconButton>TW</IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          Copyright © 2021 The Dasvandh Network. All Rights Reserved.
        </Typography>
        <Button>Back to Top</Button>
      </Grid>
    </Grid>
  );
}

export default AppFooter;
